// const Url = "https://client.securefx.net";
// const Url = "https://referral.securefx.net";
const Url=".";

var fullURL = window.location.href;
var baseURL = fullURL.split("/").slice(0, 3).join("/");

// const IsApprove = {
//   is_app: 1,
//   auth: "9db81b-f7dbfe-ef08ad"  ,
//   user_id: "2",
//   is_crm: 1,
// };
const IsApprove = "";
export { Url, IsApprove,baseURL };
